<svg class="logo" viewBox="0 0 143 54" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M67.74 0.810059V16.8501C67.74 20.2901 66.07 22.0701 63.28 22.0701C60.48 22.0701 58.81 20.2901 58.81 16.8501V0.810059H52.57V17.3501C52.57 23.9001 56.66 27.9501 63.28 27.9501C69.9 27.9501 73.99 23.9001 73.99 17.3501V0.810059H67.74ZM34.02 15.2701L36.93 6.11006L39.84 15.2701H34.02ZM32.59 0.810059L23.36 27.3001H30.17L32.21 20.9401H41.67L43.68 27.3001H50.49L41.29 0.810059H32.59ZM12.6 21.5901H6.89001V16.1401H12.6C14.49 16.1401 15.51 17.0901 15.51 18.8601C15.51 20.5301 14.45 21.5901 12.6 21.5901ZM6.88001 6.53006H12.22C13.73 6.53006 14.76 7.21006 14.76 8.69006C14.76 10.0501 13.85 10.8501 12.22 10.8501H6.88001V6.53006ZM17.82 13.3001C19.67 12.0901 21.07 10.0501 21.07 7.51006C21.07 3.46006 17.97 0.810059 13.12 0.810059H0.640015V27.3001H13.5C18.61 27.3001 21.83 24.0101 21.83 19.4701C21.83 16.4801 20.12 14.3601 17.82 13.3001Z"
    fill="currentColor" />
  <path d="M23.35 36.03H25.84L31.77 53.48H29.28L23.35 36.03Z" fill="currentColor" />
  <path
    d="M31.05 36.03H34.17L46.86 49.44L42.3 36.03H44.79L50.72 53.48H47.63L34.89 40.02L39.48 53.48H36.99L31.05 36.03Z"
    fill="currentColor" />
  <path
    d="M53.61 48.55H56.25C56.81 50.44 58.66 51.6199 61.07 51.6199C63.26 51.6199 64.39 50.4999 64.29 48.9299C64.18 47.1399 62.58 46.16 60.51 45.89L58.1 45.57C53.9 45.02 51.62 43.08 51.44 40.21C51.28 37.59 53.28 35.7 56.82 35.7C60.36 35.7 62.81 37.44 63.79 40.39H61.17C60.6 38.77 59.05 37.8999 57.08 37.8999C55.14 37.8999 53.92 38.8499 54.01 40.2899C54.11 41.8899 55.64 42.88 58.1 43.21L60.61 43.53C64.33 44.03 66.7 46.1199 66.88 49.0399C67.05 51.8799 65.03 53.85 61.29 53.85C57.37 53.83 54.59 51.86 53.61 48.55Z"
    fill="currentColor" />
  <path d="M67.32 36.03H69.81L75.74 53.48H73.25L67.32 36.03Z" fill="currentColor" />
  <path
    d="M76.51 42.7899C76.23 38.1799 78.84 35.6899 82.55 35.6899C85.94 35.6899 88.79 37.4799 90.44 41.2199H87.8C86.88 39.1999 85.01 37.9999 82.94 37.9999C80.62 37.9999 78.94 39.4699 79.15 42.8899C79.4 46.8999 82.13 51.5899 86.56 51.5899C89.1 51.5899 90.68 50.1199 90.08 47.2299H83.7L83.56 44.9599H91.74L93.84 51.0899L94.64 53.4799H92.55L91.5 50.5399C91.13 52.5799 89.38 53.8299 86.64 53.8299C80.56 53.8299 76.82 47.7699 76.51 42.7899Z"
    fill="currentColor" />
  <path
    d="M108.08 45.48H99.33L102.04 53.48H99.55L93.62 36.03H96.11L98.51 43.13H107.26L104.85 36.03H107.34L113.27 53.48H110.78L108.08 45.48Z"
    fill="currentColor" />
  <path
    d="M116.27 38.3801H110.61L110.47 36.04H123.93L124.07 38.3801H118.76L123.9 53.4901H121.41L116.27 38.3801Z"
    fill="currentColor" />
  <path
    d="M129.06 48.55H131.7C132.26 50.44 134.11 51.6199 136.52 51.6199C138.71 51.6199 139.84 50.4999 139.74 48.9299C139.63 47.1399 138.02 46.16 135.96 45.89L133.55 45.57C129.35 45.02 127.07 43.08 126.89 40.21C126.73 37.59 128.73 35.7 132.27 35.7C135.81 35.7 138.26 37.44 139.24 40.39H136.62C136.05 38.77 134.5 37.8999 132.53 37.8999C130.59 37.8999 129.37 38.8499 129.46 40.2899C129.56 41.8899 131.09 42.88 133.55 43.21L136.06 43.53C139.78 44.03 142.15 46.1199 142.33 49.0399C142.5 51.8799 140.48 53.85 136.74 53.85C132.82 53.83 130.04 51.86 129.06 48.55Z"
    fill="currentColor" />
</svg>
