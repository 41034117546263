import { InjectionToken } from '@angular/core';

export interface CookiehandlingServiceInterface {
  setDomain(domain: string): void;

  write(name: string, value: string, expires?: Date, domain?: string, path?: string): void;

  read(name: string): string;
}

export const COOKIEHANDLING_SERVICE = new InjectionToken<CookiehandlingServiceInterface>('COOKIEHANDLING_SERVICE');
