import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { DIRECTUS_INSTANCE } from '@planone/preview/data-access-directus';
import { PreviewFeatureShellModule } from '@planone/preview/feature-shell';

import { COOKIEHANDLING_CONFIG } from '@planone/shared/util-cookie-handling';

import { AppComponent } from './app.component';

import { environment } from '../environments/environment';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    StoreModule.forRoot(
      {},
      {
        metaReducers: [],
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
        },
      }
    ),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot(),
    PreviewFeatureShellModule,
  ],
  providers: [
    {
      provide: DIRECTUS_INSTANCE,
      useValue: environment.directus,
    },
    {
      provide: COOKIEHANDLING_CONFIG,
      useValue: {
        production: environment.production,
        defaultDomain: '',
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
