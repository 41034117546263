import { Component } from '@angular/core';

@Component({
  selector: 'planone-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  title = 'bau365-preview';
}
