import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';

import { SelfserviceUiShellModule } from '@planone/preview/ui-shell';

import { ShellComponent } from './shell/shell.component';

export const selfserviceFeatureShellRoutes: Route[] = [
  {
    path: '',
    component: ShellComponent,
    children: [
      {
        path: 'exhibitors',
        loadChildren: () =>
          import('@planone/preview/feature-exhibitors').then((module) => module.PreviewFeatureExhibitorsModule),
      },
    ],
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(selfserviceFeatureShellRoutes, {
      anchorScrolling: 'enabled',
      scrollOffset: [0, 128],
      scrollPositionRestoration: 'enabled',
      onSameUrlNavigation: 'reload',
    }),
    SelfserviceUiShellModule,
  ],
  exports: [RouterModule],
  declarations: [ShellComponent],
})
export class PreviewFeatureShellModule {}
