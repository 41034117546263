import { EnvironmentInterface } from './environment.interface';

export const environment: EnvironmentInterface = {
  production: true,
  directus: {
    base_url: 'fairs.bau-insights.de',
    secure: true,
    fair_as_subdomain: true,
  },
};
