import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { LayoutComponent } from './layout/layout.component';
import { LogoDarkComponent } from './logo-dark/logo-dark.component';

@NgModule({
  imports: [CommonModule, RouterModule],
  declarations: [LayoutComponent, HeaderComponent, FooterComponent, LogoDarkComponent],
  exports: [LayoutComponent, HeaderComponent, FooterComponent, LogoDarkComponent],
})
export class SelfserviceUiShellModule {}
